var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', [_c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": _vm.$vuetify.breakpoint.mdAndDown ? 12 : 9
    }
  }, [_c('v-stepper', {
    attrs: {
      "elevation": "0",
      "flat": "",
      "alt-labels": ""
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-header', {
    staticStyle: {
      "box-shadow": "none"
    }
  }, [_c('v-stepper-step', {
    attrs: {
      "editable": _vm.info && !_vm.isEmpty(_vm.info),
      "edit-icon": "mdi-check-circle",
      "complete-icon": "mdi-check-circle",
      "complete": _vm.step > 1,
      "step": 1
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('div', {
          attrs: {
            "id": "createTeamTab"
          }
        }, [_vm._v(" Create Team "), _c('vue-tooltip', {
          attrs: {
            "icon-text": "mdi-help-circle-outline",
            "text": "Your team will hold everything and you can join multiple teams"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "edit-icon": "mdi-check-circle",
      "editable": _vm.info && !_vm.isEmpty(_vm.info),
      "complete": _vm.step > 2,
      "step": 2
    }
  }, [_c('div', {
    attrs: {
      "id": "selectInviteTab"
    }
  }, [_vm._v(" Invite Members "), _c('vue-tooltip', {
    attrs: {
      "icon-text": "mdi-help-circle-outline",
      "text": "Invite your team as owners, developers and testers"
    }
  })], 1)]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "edit-icon": "mdi-check-circle",
      "editable": _vm.info && !_vm.isEmpty(_vm.info),
      "complete": _vm.step > 3,
      "step": 3
    }
  }, [_c('div', {
    attrs: {
      "id": "selectedAppDownload"
    }
  }, [_vm._v("Get App")])]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "edit-icon": "mdi-check-circle",
      "editable": _vm.info && !_vm.isEmpty(_vm.info),
      "complete": _vm.step > 4,
      "step": 4
    }
  }, [_c('div', {
    staticClass: "darkGrey--text",
    attrs: {
      "id": "createReleaseTab"
    }
  }, [_vm._v(" Create Release "), _c('vue-tooltip', {
    attrs: {
      "icon-text": "mdi-help-circle-outline",
      "text": "Upload your Android (APK) & iOS (IPA) or invite your developer to do it on behalf of you"
    }
  })], 1)])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    staticClass: "px-0",
    attrs: {
      "step": 1
    }
  }, [_vm.step === 1 ? _c('div', [_vm.getInactiveUserMessage && _vm.getIsUserDisabled ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage || 'Your membership in this team is inactive, please contact the team owners/admins for more info'
    }
  })], 1) : _c('div', [_c('v-col', {
    staticClass: "text-center font35 extra-bold",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Create your awesome team ")]), !_vm.loading ? _c('create-team', {
    attrs: {
      "team": _vm.team,
      "in-team": _vm.inTeam
    },
    on: {
      "update": _vm.updateTeam,
      "openSubscriptionModal": function openSubscriptionModal($event) {
        _vm.openSubscriptionModal = true;
      },
      "next-step": _vm.pushToNextStep,
      "updateInTeam": function updateInTeam($event) {
        _vm.inTeam = true;
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()]), _c('v-stepper-content', {
    staticClass: "px-0",
    attrs: {
      "step": 2
    }
  }, [_vm.step == 2 ? _c('div', [_c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "justify": "center"
    }
  }, [_vm.getIsUserDisabled ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage
    }
  })], 1)] : [_c('v-col', {
    staticClass: "text-center font35 extra-bold",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Invite your team ")]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('invites-list', {
    staticClass: "mx-3"
  })], 1)], _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "id": "stepOneNext",
      "loading": _vm.busy,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        _vm.step = 3;
      }
    }
  }, [_vm._v(" Next "), _c('img', {
    staticClass: "ml-3",
    attrs: {
      "src": "/media/svg/icon-arrow-right-light.svg",
      "alt": "",
      "height": "20"
    }
  })])], 1)], 2)], 1) : _vm._e()]), _c('v-stepper-content', {
    staticClass: "px-0",
    attrs: {
      "step": 3
    }
  }, [_vm.step == 3 ? _c('div', [_c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "justify": "center"
    }
  }, [_vm.getIsUserDisabled ? [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage
    }
  })], 1)] : [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center font35 extra-bold darkGrey--text"
  }, [_vm._v(" Download our app ")]), _c('v-spacer'), _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Your team will be notified to install your private app releases, share feedback and more in our app ")]), _c('v-spacer')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "radius-10 pb-12 mx-md-3",
    class: {
      'pt-9': _vm.$vuetify.breakpoint.smAndDown,
      'px-5': _vm.$vuetify.breakpoint.mdAndDown
    },
    attrs: {
      "height": "250",
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex justify-center align-center text-center",
    staticStyle: {
      "height": "46%"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('download-app-modal', {
    attrs: {
      "outside": "",
      "no-close": "",
      "store-link": _vm.androidLink
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('download-app-modal', {
    attrs: {
      "outside": "",
      "no-close": "",
      "store-link": _vm.iosLink
    }
  })], 1)], 1)], 1)], 1)])], _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "id": "stepOneNext",
      "loading": _vm.busy,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        _vm.step = 4;
      }
    }
  }, [_vm._v(" Next "), _c('img', {
    staticClass: "ml-3",
    attrs: {
      "src": "/media/svg/icon-arrow-right-light.svg",
      "alt": "",
      "height": "20"
    }
  })])], 1)], 2)], 1) : _vm._e()]), _c('v-stepper-content', {
    class: _vm.$vuetify.breakpoint.mdAndDown ? 'px-0' : '',
    attrs: {
      "step": 4
    }
  }, [_vm.step == 4 ? _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('div', [_vm.getInactiveUserMessage && _vm.getIsUserDisabled ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage || 'Your membership in this team is inactive, please contact the team owners/admins for more info'
    }
  })], 1) : _c('div', [_c('v-col', {
    staticClass: "text-center font35 extra-bold",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Create your first release ")]), _c('div', {
    staticClass: "radius-10 pr-5 py-7 mx-2",
    class: {
      'pt-15': _vm.$vuetify.breakpoint.smAndDown
    },
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('v-btn', {
    staticClass: "mt-3 text-transform-none",
    attrs: {
      "absolute": "",
      "top": "",
      "right": "",
      "depressed": "",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'apps'
        });
      }
    }
  }, [_c('div', {
    staticClass: "font14"
  }, [_vm._v("I will do it later")]), _c('img', {
    staticClass: "ml-3",
    attrs: {
      "src": "/media/svg/icon-arrow-right-light.svg",
      "alt": "",
      "height": "20"
    }
  })])], 1), _c('create-release', {
    staticClass: "ml-7 mr-2",
    attrs: {
      "no-header": "",
      "from-started": ""
    },
    on: {
      "openApp": function openApp($event) {
        _vm.step = 2;
      },
      "goNext": _vm.nextButtonStep2
    }
  })], 1)], 1)])])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('v-snackbar', {
    attrs: {
      "color": _vm.notify.color
    },
    model: {
      value: _vm.notify.show,
      callback: function callback($$v) {
        _vm.$set(_vm.notify, "show", $$v);
      },
      expression: "notify.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.notify.message) + " ")]), _c('Modal', {
    attrs: {
      "width": "700",
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openReleaseVideoModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$user;
        return [_c('video-modal', {
          attrs: {
            "title": "Create your release",
            "first-message": "TestApp.io was built by developers to solve the pain of app\n              distribution 💪",
            "second-message": "Check out our walkthrough video to get the full advantage of\n              TestApp.io",
            "video-class": "wistia_embed wistia_async_rgvr4l5zf9 email=".concat((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.id)
          },
          on: {
            "close": function close($event) {
              _vm.openReleaseVideoModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openReleaseVideoModal,
      callback: function callback($$v) {
        _vm.openReleaseVideoModal = $$v;
      },
      expression: "openReleaseVideoModal"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1000 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-get-started": ""
          },
          on: {
            "close": function close($event) {
              _vm.reset(), _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }