<template>
  <div>
    <!-- <div v-if="loading">
      <loader />
    </div> -->
    <v-container>
      <v-row class="mt-4" justify="center">
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9">
          <v-stepper elevation="0" flat v-model="step" alt-labels>
            <v-stepper-header style="box-shadow: none">
              <v-stepper-step
                :editable="info && !isEmpty(info)"
                edit-icon="mdi-check-circle"
                complete-icon="mdi-check-circle"
                :complete="step > 1"
                :step="1"
              >
                <template #default>
                  <div id="createTeamTab">
                    Create Team
                    <vue-tooltip
                      icon-text="mdi-help-circle-outline"
                      text="Your team will hold everything and you can join multiple teams"
                    ></vue-tooltip>
                  </div>
                </template>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                edit-icon="mdi-check-circle"
                :editable="info && !isEmpty(info)"
                :complete="step > 2"
                :step="2"
              >
                <div id="selectInviteTab">
                  Invite Members
                  <vue-tooltip
                    icon-text="mdi-help-circle-outline"
                    text="Invite your team as owners, developers and testers"
                  ></vue-tooltip>
                </div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                edit-icon="mdi-check-circle"
                :editable="info && !isEmpty(info)"
                :complete="step > 3"
                :step="3"
              >
                <div id="selectedAppDownload">Get App</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                edit-icon="mdi-check-circle"
                :editable="info && !isEmpty(info)"
                :complete="step > 4"
                :step="4"
              >
                <div id="createReleaseTab" class="darkGrey--text">
                  Create Release
                  <vue-tooltip
                    icon-text="mdi-help-circle-outline"
                    text="Upload your Android (APK) & iOS (IPA) or invite your developer to do it on behalf of you"
                  ></vue-tooltip>
                </div>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content class="px-0" :step="1">
                <div v-if="step === 1">
                  <div v-if="getInactiveUserMessage && getIsUserDisabled">
                    <no-data
                      :first-text="
                        getInactiveUserMessage ||
                        'Your membership in this team is inactive, please contact the team owners/admins for more info'
                      "
                    ></no-data>
                  </div>
                  <div v-else>
                    <v-col cols="12" class="text-center font35 extra-bold">
                      Create your awesome team
                    </v-col>
                    <create-team
                      v-if="!loading"
                      :team="team"
                      :in-team="inTeam"
                      @update="updateTeam"
                      @openSubscriptionModal="openSubscriptionModal = true"
                      @next-step="pushToNextStep"
                      @updateInTeam="inTeam = true"
                    ></create-team>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content class="px-0" :step="2">
                <div v-if="step == 2">
                  <v-row justify="center" class="mt-3">
                    <template v-if="getIsUserDisabled">
                      <v-col cols="12" md="12">
                        <no-data :first-text="getInactiveUserMessage"></no-data>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12" class="text-center font35 extra-bold">
                        Invite your team
                      </v-col>
                      <v-col cols="12" md="12">
                        <invites-list class="mx-3" />
                      </v-col>
                    </template>
                    <v-col cols="12">
                      <v-btn
                        id="stepOneNext"
                        :loading="busy"
                        @click="step = 3"
                        color="primary"
                        :outlined="$vuetify.theme.dark"
                        class="float-right"
                      >
                        Next
                        <img
                          src="/media/svg/icon-arrow-right-light.svg"
                          alt
                          class="ml-3"
                          height="20"
                        />
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- check this -->
                </div>
              </v-stepper-content>
              <v-stepper-content class="px-0" :step="3">
                <div v-if="step == 3">
                  <v-row justify="center" class="mt-3">
                    <template v-if="getIsUserDisabled">
                      <v-col cols="12" md="8">
                        <no-data :first-text="getInactiveUserMessage"></no-data>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12">
                        <div
                          class="text-center font35 extra-bold darkGrey--text"
                        >
                          Download our app
                        </div>

                        <v-spacer></v-spacer>
                        <div class="text-center">
                          Your team will be notified to install your private app
                          releases, share feedback and more in our app
                        </div>
                        <v-spacer></v-spacer>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div
                          height="250"
                          flat
                          class="radius-10 pb-12 mx-md-3"
                          :class="{
                            'pt-9': $vuetify.breakpoint.smAndDown,
                            'px-5': $vuetify.breakpoint.mdAndDown,
                          }"
                        >
                          <v-card-text
                            class="d-flex justify-center align-center text-center"
                            style="height: 46%"
                          >
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex">
                                <download-app-modal
                                  outside
                                  no-close
                                  :store-link="androidLink"
                                ></download-app-modal>
                              </v-col>
                              <v-col cols="12" md="6" class="d-flex">
                                <download-app-modal
                                  outside
                                  no-close
                                  :store-link="iosLink"
                                ></download-app-modal>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </div>
                      </v-col>
                    </template>
                    <v-col cols="8">
                      <v-btn
                        id="stepOneNext"
                        :loading="busy"
                        @click="step = 4"
                        color="primary"
                        :outlined="$vuetify.theme.dark"
                        class="float-right"
                      >
                        Next
                        <img
                          src="/media/svg/icon-arrow-right-light.svg"
                          alt
                          class="ml-3"
                          height="20"
                        />
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- check this -->
                </div>
              </v-stepper-content>
              <v-stepper-content
                :class="$vuetify.breakpoint.mdAndDown ? 'px-0' : ''"
                :step="4"
              >
                <v-row v-if="step == 4" justify="center" class="mt-3">
                  <v-col cols="12" md="10">
                    <div>
                      <div v-if="getInactiveUserMessage && getIsUserDisabled">
                        <no-data
                          :first-text="
                            getInactiveUserMessage ||
                            'Your membership in this team is inactive, please contact the team owners/admins for more info'
                          "
                        ></no-data>
                      </div>
                      <div v-else>
                        <v-col cols="12" class="text-center font35 extra-bold">
                          Create your first release
                        </v-col>
                        <div
                          flat
                          class="radius-10 pr-5 py-7 mx-2"
                          :class="{
                            'pt-15': $vuetify.breakpoint.smAndDown,
                          }"
                        >
                          <v-card-title>
                            <v-btn
                              absolute
                              top
                              right
                              depressed
                              color="primary"
                              :outlined="$vuetify.theme.dark"
                              class="mt-3 text-transform-none"
                              @click="$router.push({ name: 'apps' })"
                            >
                              <div class="font14">I will do it later</div>
                              <img
                                src="/media/svg/icon-arrow-right-light.svg"
                                alt
                                class="ml-3"
                                height="20"
                              />
                            </v-btn>
                          </v-card-title>
                          <create-release
                            no-header
                            from-started
                            class="ml-7 mr-2"
                            @openApp="step = 2"
                            @goNext="nextButtonStep2"
                          />
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <!-- <v-col md="3" cols="12">
                        <download-app></download-app>
                      </v-col> -->
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <!--          <v-row>-->
          <!--            <v-col v-for="(item, index) in tabs" :key="index" cols="3">-->
          <!--              <v-list>-->
          <!--                <v-list-item @click="step = item.value">-->
          <!--                  {{ item.title }}-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </v-col>
      </v-row>
    </v-container>
    <!--end: Wizard-->
    <v-snackbar v-model="notify.show" :color="notify.color">
      {{ notify.message }}
    </v-snackbar>
    <Modal
      width="700"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openReleaseVideoModal = false"
      v-model="openReleaseVideoModal"
    >
      <template #card>
        <video-modal
          title="Create your release"
          first-message="TestApp.io was built by developers to solve the pain of app
                distribution 💪"
          second-message="Check out our walkthrough video to get the full advantage of
                TestApp.io"
          :video-class="`wistia_embed wistia_async_rgvr4l5zf9 email=${user?.id}`"
          @close="openReleaseVideoModal = false"
        ></video-modal>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 1000 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openSubscriptionModal = false"
      v-model="openSubscriptionModal"
    >
      <template #message>
        <plan-pricing
          from-modal
          from-get-started
          @close="reset(), (openSubscriptionModal = false)"
        ></plan-pricing>
      </template>
    </Modal>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import InvitesList from "@/view/components/Invites/List";
import { mapState, mapGetters, mapMutations } from "vuex";
import { get, isEmpty } from "@/core/services/helper.service";
import { UPDATE_TEAM, GET_TEAM_INFO } from "@/store/team/manageTeam.module";
// import CreateAppStep from "@/view/components/App/CreateAppStep.vue";
import CreateRelease from "@/view/components/Releases/CreateRelease";
import CreateTeam from "@/view/components/Team/Create.vue";
import PlanPricing from "@/view/components/Team/PlanPricing";
import { CREATE } from "@/store/apps/createApp.module";
import VideoModal from "@/view/components/Common/VideoModal";
import DownloadAppModal from "@/view/components/Common/DownloadAppModal";
// import DownloadApp from "@/view/components/User/DownloadApp.vue";
// import InfoCard from "@/view/pages/user/get-started/InfoCard.vue";

export default {
  stepNames: {
    "started-team": 1,
    "started-invite": 2,
    "download-app": 3,
    "started-releases": 4,
  },
  data() {
    return {
      step: 1,
      team: {},
      openReleaseVideoModal: false,
      androidLink: {
        url: "https://play.google.com/store/apps/details?id=testapp.io",
        platform: "android",
      },
      iosLink: {
        url: "https://apps.apple.com/us/app/testapp-io/id1518972541",
        platform: "ios",
      },
      tabs: [
        {
          title: "Team",
          icon: "",
          value: 1,
        },
        {
          title: "Invites",
          value: 2,
        },
        {
          title: "Create Release",
          value: 3,
        },
      ],
      isEmpty,
      haveTeam: true,
      loading: false,
      openSubscriptionModal: false,
      inTeam: null,
      busy: false,
      activeTab: "email",
      notify: {
        show: false,
        message: "",
        color: "",
      },
      switchYearly: true,
      // this 'show' is for the tooltips, there are 3 tooltips in this page, each one has own propertie
      show: null,
    };
  },
  mixins: [veeValidate],
  components: {
    DownloadAppModal,
    VideoModal,
    InvitesList,
    // CreateAppStep,
    CreateRelease,
    PlanPricing,
    // DownloadApp,
    CreateTeam,
    // InfoCard
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      info: (state) => get(state, "manageTeam.info", ""),
      teamInvitesList: (state) => get(state, "invite.teamInvitesList", ""),
    }),
    ...mapGetters({
      user: "currentUser",
      getTeamName: "getTeamName",
      getRedirectedFromPlan: "getRedirectedFromPlan",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    haveInvites() {
      return isEmpty(this.teamInvitesList);
    },
  },
  watch: {
    info(val) {
      this.team = { ...val };
    },
    step(val) {
      if (val === 4) {
        this.openReleaseVideoModal = true;
      }
      if (Object.keys(this.$options.stepNames)[val - 1] === this.$route.name) {
        return;
      }
      this.$router
        .push({
          name: Object.keys(this.$options.stepNames)[val - 1],
        })
        .catch(() => {});
    },
  },
  async created() {
    await this.checkTeam();
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setTeamName: "setTeamName",
    }),
    pushToNextStep() {
      this.$store
        .dispatch(CREATE, {
          name: this.getTeamName,
        })
        .then(() => {
          this.step = 2;
        });
    },
    fetchMember() {
      if (this.info && !isEmpty(this.info)) {
        this.step = 2;
      } else {
        this.notifyInfoMessage({ message: "Create or be a in a team first" });
      }
    },

    checkTeam() {
      this.inTeam = null;
      if (this.loading) return;
      this.loading = true;
      if (this.info && !isEmpty(this.info)) {
        this.team = { ...this.info };
        this.loading = false;
        if (this.$route.name === "get-started") {
          this.step = 1;
        } else {
          this.step = this.$options.stepNames[this.$route.name];
        }
        this.inTeam = true;
        if (this.team && this.team.name) {
          // this.$router.push("/Team");
        }
      } else {
        this.$store
          .dispatch(GET_TEAM_INFO)
          .then(() => {
            this.inTeam = true;
            this.team = { ...this.info };
            if (this.$route.name === "get-started") {
              this.step = 1;
            } else {
              this.step = this.$options.stepNames[this.$route.name];
            }
            this.loading = false;
            if (this.team && this.team.name) {
              // this.$router.push("/Team");
            }
          })
          .catch(() => {
            this.step = 1;
            this.haveTeam = false;
            this.inTeam = false;
            this.loading = false;
          });
      }
    },

    updateTeam(dataToPost) {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(UPDATE_TEAM, dataToPost)
        .then((response) => {
          this.busy = false;
          this.notifyUserMessage({
            message: response.message || "Successfully updated",
          });
          this.step = 2;
          this.setTeamName(dataToPost.name);
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong");
          this.busy = false;
        });
    },
    nextButtonStep1() {
      this.step = 2;
    },
    nextButtonStep2() {
      this.step = 3;
    },
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.overflow-hidden {
  overflow: hidden !important;
}
.invites {
  .v-tabs-bar {
    display: none !important;
  }
}
</style>
